var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "transparent", tile: "", elevation: "0" } },
    [
      _c(
        "v-card",
        {
          staticClass: "py-sm-8 py-4",
          attrs: { color: "light_gray", tile: "", elevation: "0" },
        },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c("v-col", { attrs: { cols: "11", lg: "9" } }, [
                _c(
                  "span",
                  { staticClass: "text-sm-h5 text-body-1 font-weight-bold" },
                  [_vm._v(_vm._s(_vm.$t("label.checkout")))]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-8", attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "11", lg: "9" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "7", cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-sm-6 pa-4 py-6 rounded-lg",
                          attrs: {
                            color: "transparent",
                            tile: "",
                            elevation: "5",
                          },
                        },
                        [
                          !_vm.isDeposit
                            ? _c(
                                "div",
                                { staticClass: "mb-6" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-weight-bold mb-2 text-sm-body-1 text-body-2",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("label.selectDeliveryMethod")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    _vm._l(
                                      _vm.orderDeliveryMethods,
                                      function (deliveryMethod) {
                                        return _c(
                                          "v-col",
                                          {
                                            key: deliveryMethod.name,
                                            staticClass:
                                              "delivery-option-button-box",
                                            attrs: {
                                              cols: "12",
                                              sm: "5",
                                              md: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "cursor-pointer delivery-option-button text-capitalize px-4 py-2 rounded-lg",
                                                class:
                                                  _vm.deliveryMethodId ==
                                                  deliveryMethod.name
                                                    ? "active"
                                                    : "",
                                                on: {
                                                  click: function ($event) {
                                                    !_vm.isMakeOldOrderPayment
                                                      ? (_vm.deliveryMethodId =
                                                          deliveryMethod.name)
                                                      : ""
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", {
                                                  class:
                                                    _vm.deliveryMethodId ==
                                                    deliveryMethod.name
                                                      ? "stroke--unbox_primary"
                                                      : "stroke--light_gray",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      "$" +
                                                        deliveryMethod.name +
                                                        "Icon"
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-body-2",
                                                    class:
                                                      _vm.deliveryMethodId ==
                                                      deliveryMethod.name
                                                        ? "unbox_black--text"
                                                        : "text_light_gray--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        deliveryMethod.text
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                  _vm.isDeliveryMethodError
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "red--text text-body-2 mb-2",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "label.invalidDeliveryMethod"
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.deliveryMethodId == _vm.deliveryMethod.DELIVERY
                            ? _c("div", { staticClass: "mb-6" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bold mb-2 text-sm-body-1 text-body-2",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("label.yourShippingAddress")
                                      )
                                    ),
                                  ]
                                ),
                                _vm.shippingAddress != null
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "billing-address-box py-4 px-6 rounded-lg cursor-pointer text-sm-body-1 text-body-2",
                                        on: {
                                          click:
                                            _vm.openChangeShippingAddressDialog,
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            attrs: {
                                              "no-gutters": "",
                                              justify: "space-between",
                                              align: "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "10" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.shippingAddress
                                                          .display_name
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.shippingAddress.phone
                                                    )
                                                  ),
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.shippingAddress
                                                        .address
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            !_vm.isMakeOldOrderPayment
                                              ? _c(
                                                  "v-col",
                                                  { attrs: { cols: "auto" } },
                                                  [
                                                    _c("v-icon", {
                                                      staticClass:
                                                        "stroke--unbox_green",
                                                      domProps: {
                                                        textContent:
                                                          _vm._s(
                                                            "$arrowRightIcon"
                                                          ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "billing-address-box py-4 px-6 rounded-lg cursor-pointer text-sm-body-1 text-body-2",
                                        on: { click: _vm.openAddAddressDialog },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            attrs: {
                                              "no-gutters": "",
                                              justify: "space-between",
                                              align: "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "10" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "label.addAddress"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "auto" } },
                                              [
                                                _c("v-icon", {
                                                  staticClass:
                                                    "stroke--unbox_green",
                                                  domProps: {
                                                    textContent:
                                                      _vm._s("$plusCircleIcon"),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                _vm.isShippingAddressError
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "red--text text-body-2 mb-2",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "label.invalidShippingAddress"
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "mb-6" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "font-weight-bold mb-2 text-sm-body-1 text-body-2",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("label.yourBillingAddress"))
                                ),
                              ]
                            ),
                            _vm.billingAddress != null
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "billing-address-box py-4 px-6 rounded-lg cursor-pointer text-sm-body-1 text-body-2",
                                    on: {
                                      click: _vm.openChangeBillingAddressDialog,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          justify: "space-between",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _c("v-col", { attrs: { cols: "10" } }, [
                                          _c(
                                            "div",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.billingAddress
                                                    .display_name
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.billingAddress.phone)
                                            ),
                                          ]),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.billingAddress.address)
                                            ),
                                          ]),
                                        ]),
                                        !_vm.isMakeOldOrderPayment
                                          ? _c(
                                              "v-col",
                                              { attrs: { cols: "auto" } },
                                              [
                                                _c("v-icon", {
                                                  staticClass:
                                                    "stroke--unbox_green",
                                                  domProps: {
                                                    textContent:
                                                      _vm._s("$arrowRightIcon"),
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "billing-address-box py-4 px-6 rounded-lg cursor-pointer text-sm-body-1 text-body-2",
                                    on: { click: _vm.openAddAddressDialog },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          justify: "space-between",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _c("v-col", { attrs: { cols: "10" } }, [
                                          _c(
                                            "div",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("label.addAddress")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "auto" } },
                                          [
                                            _c("v-icon", {
                                              staticClass:
                                                "stroke--unbox_green",
                                              domProps: {
                                                textContent:
                                                  _vm._s("$plusCircleIcon"),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                            _vm.isBillingAddressError
                              ? _c(
                                  "div",
                                  { staticClass: "red--text text-body-2 mb-2" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("label.invalidBillingAddress")
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "mb-6" },
                            [
                              _c(
                                "div",
                                { staticClass: "font-weight-bold mb-2" },
                                [_vm._v(_vm._s(_vm.$t("label.yourItems")))]
                              ),
                              _c("v-data-table", {
                                ref: "cartTable",
                                staticClass: "cart-table unbox_scrollbar",
                                attrs: {
                                  "items-per-page": _vm.checkoutCart.length,
                                  "item-key": "productDisplaySku",
                                  items: _vm.checkoutCart,
                                  "hide-default-footer": "",
                                  "no-data-text": _vm.$t("label.cartEmpty"),
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "full-width px-0 d-table",
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass:
                                                      "my-2 mx-1 rounded-lg",
                                                    attrs: {
                                                      color: "transparent",
                                                      tile: "",
                                                      elevation: "2",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "table",
                                                      {
                                                        staticClass:
                                                          "full-width d-table",
                                                      },
                                                      [
                                                        _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "cart-productColumn image v-align-top",
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticClass:
                                                                  "cart-productImage ma-2",
                                                                attrs: {
                                                                  src:
                                                                    _vm.shared
                                                                      .MEDIA_SERVER_URL +
                                                                    "/products/variants/" +
                                                                    item.productDisplaySku +
                                                                    ".png",
                                                                  alt:
                                                                    item.productName +
                                                                    " " +
                                                                    item.variation,
                                                                  title:
                                                                    item.productName +
                                                                    " " +
                                                                    item.variation,
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "cart-productColumn",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "py-4 px-2",
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    !_vm.isDeposit
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "font-weight-black text-body-1 mb-2 red--text",
                                                                            class:
                                                                              _vm
                                                                                .cartStock
                                                                                .length <=
                                                                                0 ||
                                                                              (_vm.cartStock.find(
                                                                                function (
                                                                                  x
                                                                                ) {
                                                                                  return (
                                                                                    x.display_sku ==
                                                                                    item.productDisplaySku
                                                                                  )
                                                                                }
                                                                              ) !=
                                                                                null &&
                                                                                _vm.cartStock.find(
                                                                                  function (
                                                                                    x
                                                                                  ) {
                                                                                    return (
                                                                                      x.display_sku ==
                                                                                      item.productDisplaySku
                                                                                    )
                                                                                  }
                                                                                )
                                                                                  .quantity >
                                                                                  0)
                                                                                ? "d-none"
                                                                                : "",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "label.soldOut"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-bold text-sm-body-1 text-body-2 mb-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.productName
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-sm-body-1 text-body-2 mb-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.variation
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-bold text-sm-body-1 text-body-2 unbox_red--text mb-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$shared.formatCurrency(
                                                                                item.discountedPrice +
                                                                                  (isNaN(
                                                                                    item.markUpPrice
                                                                                  )
                                                                                    ? 0
                                                                                    : item.markUpPrice)
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      staticClass:
                                                                        "mt-2",
                                                                      attrs: {
                                                                        align:
                                                                          "center",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              sm: "auto",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-sm-body-1 text-body-2",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "label.quantity"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              sm: "auto",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "120px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "cart-quantity",
                                                                                  attrs:
                                                                                    {
                                                                                      readonly:
                                                                                        _vm.isMakeOldOrderPayment ||
                                                                                        _vm.isClearBalanceOrder,
                                                                                      "hide-details":
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      type: "number",
                                                                                    },
                                                                                  on: {
                                                                                    keyup:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.checkAvailableQuantity(
                                                                                          item
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  scopedSlots:
                                                                                    _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key: "prepend",
                                                                                          fn: function () {
                                                                                            return [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "font-weight-bold my-n2 text-h6 px-0",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      disabled:
                                                                                                        _vm.isMakeOldOrderPayment ||
                                                                                                        _vm.isClearBalanceOrder,
                                                                                                      "max-width":
                                                                                                        "15px",
                                                                                                      "min-width":
                                                                                                        "15px",
                                                                                                      height:
                                                                                                        "40px",
                                                                                                      text: "",
                                                                                                    },
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        $event.stopPropagation()
                                                                                                        return _vm.decreaseCartItemQuantity(
                                                                                                          item
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " - "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          },
                                                                                          proxy: true,
                                                                                        },
                                                                                        {
                                                                                          key: "append-outer",
                                                                                          fn: function () {
                                                                                            return [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "font-weight-bold my-n2 text-h6 px-0",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      disabled:
                                                                                                        _vm.isClearBalanceOrder ||
                                                                                                        _vm.isMakeOldOrderPayment ||
                                                                                                        (_vm.cartStock.find(
                                                                                                          function (
                                                                                                            x
                                                                                                          ) {
                                                                                                            return (
                                                                                                              x.display_sku ==
                                                                                                              item.productDisplaySku
                                                                                                            )
                                                                                                          }
                                                                                                        ) !=
                                                                                                          null &&
                                                                                                          _vm.cartStock.find(
                                                                                                            function (
                                                                                                              x
                                                                                                            ) {
                                                                                                              return (
                                                                                                                x.display_sku ==
                                                                                                                item.productDisplaySku
                                                                                                              )
                                                                                                            }
                                                                                                          )
                                                                                                            .quantity <
                                                                                                            item.quantity),
                                                                                                      "max-width":
                                                                                                        "15px",
                                                                                                      "min-width":
                                                                                                        "15px",
                                                                                                      height:
                                                                                                        "40px",
                                                                                                      text: "",
                                                                                                    },
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        $event.stopPropagation()
                                                                                                        return _vm.increaseCartItemQuantity(
                                                                                                          item
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " + "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          },
                                                                                          proxy: true,
                                                                                        },
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    ),
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        item.quantity,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item,
                                                                                            "quantity",
                                                                                            _vm._n(
                                                                                              $$v
                                                                                            )
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "item.quantity",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  item.insurance &&
                                                                  item.discountedPrice <
                                                                    8000.01 &&
                                                                  item.discountedPrice >
                                                                    0
                                                                    ? _c(
                                                                        "v-row",
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _c(
                                                                                    "v-row",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mb-2",
                                                                                      attrs:
                                                                                        {
                                                                                          "no-gutters":
                                                                                            "",
                                                                                          align:
                                                                                            "top",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              cols: "auto",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-checkbox",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mt-0",
                                                                                              attrs:
                                                                                                {
                                                                                                  "hide-details":
                                                                                                    "",
                                                                                                },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    item.requiredInsurance,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        item,
                                                                                                        "requiredInsurance",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                  expression:
                                                                                                    "item.requiredInsurance",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-col",
                                                                                        [
                                                                                          _c(
                                                                                            "p",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mb-0",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                " " +
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "label.insurancePlan",
                                                                                                      [
                                                                                                        _vm.$shared.formatCurrency(
                                                                                                          item
                                                                                                            .insurance
                                                                                                            .unit_price
                                                                                                        ),
                                                                                                      ]
                                                                                                    )
                                                                                                  ) +
                                                                                                  " "
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "link-text cursor-pointer",
                                                                                                  on: {
                                                                                                    click:
                                                                                                      _vm.openProtectionPlanPage,
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "label.tncsApply"
                                                                                                        )
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "stroke--link_text",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          small:
                                                                                                            "",
                                                                                                        },
                                                                                                      domProps:
                                                                                                        {
                                                                                                          textContent:
                                                                                                            _vm._s(
                                                                                                              "$exclamationMarkIcon"
                                                                                                            ),
                                                                                                        },
                                                                                                    }
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  item.requiredInsurance
                                                                                    ? _c(
                                                                                        "div",
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "font-weight-bold text-sm-body-1 text-body-2 unbox_red--text mb-2",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.$shared.formatCurrency(
                                                                                                    item
                                                                                                      .insurance
                                                                                                      .unit_price
                                                                                                  )
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            [
                                                                                              _c(
                                                                                                "v-row",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mt-2",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      align:
                                                                                                        "center",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-col",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          sm: "auto",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "span",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "text-sm-body-1 text-body-2",
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            _vm._s(
                                                                                                              _vm.$t(
                                                                                                                "label.quantity"
                                                                                                              )
                                                                                                            )
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-col",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          sm: "auto",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              width:
                                                                                                                "120px",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "v-text-field",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "cart-quantity",
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  "hide-details":
                                                                                                                    "",
                                                                                                                  outlined:
                                                                                                                    "",
                                                                                                                  dense:
                                                                                                                    "",
                                                                                                                  type: "number",
                                                                                                                },
                                                                                                              on: {
                                                                                                                keyup:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.checkInsuranceQuantity(
                                                                                                                      item
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                              scopedSlots:
                                                                                                                _vm._u(
                                                                                                                  [
                                                                                                                    {
                                                                                                                      key: "prepend",
                                                                                                                      fn: function () {
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "v-btn",
                                                                                                                            {
                                                                                                                              staticClass:
                                                                                                                                "font-weight-bold my-n2 text-h6 px-0",
                                                                                                                              attrs:
                                                                                                                                {
                                                                                                                                  "max-width":
                                                                                                                                    "15px",
                                                                                                                                  "min-width":
                                                                                                                                    "15px",
                                                                                                                                  height:
                                                                                                                                    "40px",
                                                                                                                                  text: "",
                                                                                                                                },
                                                                                                                              on: {
                                                                                                                                click:
                                                                                                                                  function (
                                                                                                                                    $event
                                                                                                                                  ) {
                                                                                                                                    $event.stopPropagation()
                                                                                                                                    return _vm.decreaseInsuranceQuantity(
                                                                                                                                      item
                                                                                                                                    )
                                                                                                                                  },
                                                                                                                              },
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                " - "
                                                                                                                              ),
                                                                                                                            ]
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      },
                                                                                                                      proxy: true,
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key: "append-outer",
                                                                                                                      fn: function () {
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "v-btn",
                                                                                                                            {
                                                                                                                              staticClass:
                                                                                                                                "font-weight-bold my-n2 text-h6 px-0",
                                                                                                                              attrs:
                                                                                                                                {
                                                                                                                                  disabled:
                                                                                                                                    _vm.cartStock.find(
                                                                                                                                      function (
                                                                                                                                        x
                                                                                                                                      ) {
                                                                                                                                        return (
                                                                                                                                          x.display_sku ==
                                                                                                                                          item.productDisplaySku
                                                                                                                                        )
                                                                                                                                      }
                                                                                                                                    ) !=
                                                                                                                                      null &&
                                                                                                                                    _vm.cartStock.find(
                                                                                                                                      function (
                                                                                                                                        x
                                                                                                                                      ) {
                                                                                                                                        return (
                                                                                                                                          x.display_sku ==
                                                                                                                                          item.productDisplaySku
                                                                                                                                        )
                                                                                                                                      }
                                                                                                                                    )
                                                                                                                                      .quantity <
                                                                                                                                      item.quantity,
                                                                                                                                  "max-width":
                                                                                                                                    "15px",
                                                                                                                                  "min-width":
                                                                                                                                    "15px",
                                                                                                                                  height:
                                                                                                                                    "40px",
                                                                                                                                  text: "",
                                                                                                                                },
                                                                                                                              on: {
                                                                                                                                click:
                                                                                                                                  function (
                                                                                                                                    $event
                                                                                                                                  ) {
                                                                                                                                    $event.stopPropagation()
                                                                                                                                    return _vm.increaseInsuranceQuantity(
                                                                                                                                      item
                                                                                                                                    )
                                                                                                                                  },
                                                                                                                              },
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                " + "
                                                                                                                              ),
                                                                                                                            ]
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      },
                                                                                                                      proxy: true,
                                                                                                                    },
                                                                                                                  ],
                                                                                                                  null,
                                                                                                                  true
                                                                                                                ),
                                                                                                              model:
                                                                                                                {
                                                                                                                  value:
                                                                                                                    item
                                                                                                                      .insurance
                                                                                                                      .quantity,
                                                                                                                  callback:
                                                                                                                    function (
                                                                                                                      $$v
                                                                                                                    ) {
                                                                                                                      _vm.$set(
                                                                                                                        item.insurance,
                                                                                                                        "quantity",
                                                                                                                        _vm._n(
                                                                                                                          $$v
                                                                                                                        )
                                                                                                                      )
                                                                                                                    },
                                                                                                                  expression:
                                                                                                                    "item.insurance.quantity",
                                                                                                                },
                                                                                                            }
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _vm.maxFreeWrapping > 0 && !_vm.isDeposit
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "gift-wrapping-engraving-section mb-6 pa-1 full-width",
                                },
                                [
                                  _c("v-divider", { staticClass: "my-4" }),
                                  _c(
                                    "v-expansion-panels",
                                    {
                                      staticClass:
                                        "my-2 rounded-lg elevation-1",
                                      attrs: {
                                        flat: "",
                                        color: "transparent",
                                        tile: "",
                                        "border-color": "#D5DDE0",
                                      },
                                      model: {
                                        value: _vm.giftWrappingModel,
                                        callback: function ($$v) {
                                          _vm.giftWrappingModel = $$v
                                        },
                                        expression: "giftWrappingModel",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel",
                                        {
                                          staticClass: "elevation-2 rounded-lg",
                                        },
                                        [
                                          _c(
                                            "v-expansion-panel-header",
                                            {
                                              staticClass: "pa-2 rounded-lg",
                                              attrs: { color: "#FFFFFF" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-body-2 font-weight-bold mt-2 px-4",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "label.giftWrapping"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-body-2 font-weight-normal mt-2 mb-4",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "unbox_red--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "label.giftWrappingLimitedOffer"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "label.giftWrappingDesc"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-expansion-panel-content",
                                            {
                                              staticClass: "rounded-lg",
                                              attrs: { color: "#FFFFFF" },
                                            },
                                            [
                                              _c("v-data-table", {
                                                ref: "cartTable",
                                                staticClass:
                                                  "cart-table unbox_scrollbar px-4",
                                                attrs: {
                                                  "items-per-page":
                                                    _vm.giftWrappingDataset
                                                      .length,
                                                  "item-key":
                                                    "productDisplaySku",
                                                  items:
                                                    _vm.giftWrappingDataset,
                                                  "hide-default-footer": "",
                                                  "no-data-text":
                                                    _vm.$t("label.cartEmpty"),
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "item",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          [
                                                            _c("tr", [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "full-width px-0 d-table",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card",
                                                                    {
                                                                      staticClass:
                                                                        "my-2 mx-1 rounded-lg",
                                                                      attrs: {
                                                                        color:
                                                                          "transparent",
                                                                        tile: "",
                                                                        elevation:
                                                                          "2",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "table",
                                                                        {
                                                                          staticClass:
                                                                            "full-width d-table",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "tr",
                                                                            [
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  staticClass:
                                                                                    "cart-productColumn image v-align-top",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "cart-productImage ma-2",
                                                                                      attrs:
                                                                                        {
                                                                                          src:
                                                                                            _vm
                                                                                              .shared
                                                                                              .MEDIA_SERVER_URL +
                                                                                            "/" +
                                                                                            item.imagePath,
                                                                                          alt:
                                                                                            item.name +
                                                                                            " " +
                                                                                            item.variation,
                                                                                          title:
                                                                                            item.name +
                                                                                            " " +
                                                                                            item.variation,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  staticClass:
                                                                                    "cart-productColumn",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "py-4 px-2",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "font-weight-bold text-sm-body-1 text-body-2 mb-2",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  item.name
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "font-weight-bold unbox_red--text mb-2 text-uppercase",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "font-weight-bold text-sm-body-1 text-body-2",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "RM 0.00 "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "font-weight-light text-decoration-line-through unbox-secondary",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$shared.formatCurrency(
                                                                                                        item.sellingPrice
                                                                                                      )
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "v-row",
                                                                                        {
                                                                                          staticClass:
                                                                                            "mt-2",
                                                                                          attrs:
                                                                                            {
                                                                                              align:
                                                                                                "center",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-col",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  sm: "auto",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-sm-body-1 text-body-2",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "label.quantity"
                                                                                                      )
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "v-col",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  sm: "auto",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      width:
                                                                                                        "120px",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-text-field",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "cart-quantity",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          readonly:
                                                                                                            _vm.isMakeOldOrderPayment ||
                                                                                                            _vm.isClearBalanceOrder,
                                                                                                          "hide-details":
                                                                                                            "",
                                                                                                          outlined:
                                                                                                            "",
                                                                                                          dense:
                                                                                                            "",
                                                                                                          type: "number",
                                                                                                        },
                                                                                                      on: {
                                                                                                        keyup:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.checkAvailableGiftWrappingQuantity(
                                                                                                              "gift",
                                                                                                              item
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                      scopedSlots:
                                                                                                        _vm._u(
                                                                                                          [
                                                                                                            {
                                                                                                              key: "prepend",
                                                                                                              fn: function () {
                                                                                                                return [
                                                                                                                  _c(
                                                                                                                    "v-btn",
                                                                                                                    {
                                                                                                                      staticClass:
                                                                                                                        "font-weight-bold my-n2 text-h6 px-0",
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          disabled:
                                                                                                                            _vm.isMakeOldOrderPayment ||
                                                                                                                            _vm.isClearBalanceOrder,
                                                                                                                          "max-width":
                                                                                                                            "15px",
                                                                                                                          "min-width":
                                                                                                                            "15px",
                                                                                                                          height:
                                                                                                                            "40px",
                                                                                                                          text: "",
                                                                                                                        },
                                                                                                                      on: {
                                                                                                                        click:
                                                                                                                          function (
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            $event.stopPropagation()
                                                                                                                            return _vm.decreaseGiftWrappingQuantity(
                                                                                                                              "gift",
                                                                                                                              item
                                                                                                                            )
                                                                                                                          },
                                                                                                                      },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        " - "
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                ]
                                                                                                              },
                                                                                                              proxy: true,
                                                                                                            },
                                                                                                            {
                                                                                                              key: "append-outer",
                                                                                                              fn: function () {
                                                                                                                return [
                                                                                                                  _c(
                                                                                                                    "v-btn",
                                                                                                                    {
                                                                                                                      staticClass:
                                                                                                                        "font-weight-bold my-n2 text-h6 px-0",
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          disabled:
                                                                                                                            _vm.isClearBalanceOrder ||
                                                                                                                            _vm.isMakeOldOrderPayment ||
                                                                                                                            (_vm.cartStock.find(
                                                                                                                              function (
                                                                                                                                x
                                                                                                                              ) {
                                                                                                                                return (
                                                                                                                                  x.display_sku ==
                                                                                                                                  item.productDisplaySku
                                                                                                                                )
                                                                                                                              }
                                                                                                                            ) !=
                                                                                                                              null &&
                                                                                                                              _vm.cartStock.find(
                                                                                                                                function (
                                                                                                                                  x
                                                                                                                                ) {
                                                                                                                                  return (
                                                                                                                                    x.display_sku ==
                                                                                                                                    item.productDisplaySku
                                                                                                                                  )
                                                                                                                                }
                                                                                                                              )
                                                                                                                                .quantity <
                                                                                                                                item.quantity),
                                                                                                                          "max-width":
                                                                                                                            "15px",
                                                                                                                          "min-width":
                                                                                                                            "15px",
                                                                                                                          height:
                                                                                                                            "40px",
                                                                                                                          text: "",
                                                                                                                        },
                                                                                                                      on: {
                                                                                                                        click:
                                                                                                                          function (
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            $event.stopPropagation()
                                                                                                                            return _vm.increaseGiftWrappingQuantity(
                                                                                                                              "gift",
                                                                                                                              item
                                                                                                                            )
                                                                                                                          },
                                                                                                                      },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        " + "
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                ]
                                                                                                              },
                                                                                                              proxy: true,
                                                                                                            },
                                                                                                          ],
                                                                                                          null,
                                                                                                          true
                                                                                                        ),
                                                                                                      model:
                                                                                                        {
                                                                                                          value:
                                                                                                            item.quantity,
                                                                                                          callback:
                                                                                                            function (
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                item,
                                                                                                                "quantity",
                                                                                                                _vm._n(
                                                                                                                  $$v
                                                                                                                )
                                                                                                              )
                                                                                                            },
                                                                                                          expression:
                                                                                                            "item.quantity",
                                                                                                        },
                                                                                                    }
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]),
                                                            _vm.giftWrappingConfirmQuantity >
                                                            0
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-expansion-panels",
                                                                      {
                                                                        staticClass:
                                                                          "my-2 mt-4",
                                                                        attrs: {
                                                                          flat: "",
                                                                          color:
                                                                            "transparent",
                                                                          "border-color":
                                                                            "transparent",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        item.quantity,
                                                                        function (
                                                                          x,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "v-expansion-panel",
                                                                            {
                                                                              key:
                                                                                "giftWrapping-" +
                                                                                index,
                                                                              attrs:
                                                                                {
                                                                                  multiple:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-expansion-panel-header",
                                                                                {
                                                                                  staticClass:
                                                                                    "pa-2 rounded-lg",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "#FFFFFF",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-sm-body-1 text-body-2 font-weight-bold mt-2 px-4",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item.fieldsName
                                                                                        ) +
                                                                                          " - " +
                                                                                          _vm._s(
                                                                                            index +
                                                                                              1
                                                                                          ) +
                                                                                          " (" +
                                                                                          _vm._s(
                                                                                            item.name
                                                                                          ) +
                                                                                          ")"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-expansion-panel-content",
                                                                                {
                                                                                  staticClass:
                                                                                    "rounded-lg",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "#FFFFFF",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "textarea",
                                                                                    {
                                                                                      directives:
                                                                                        [
                                                                                          {
                                                                                            name: "model",
                                                                                            rawName:
                                                                                              "v-model",
                                                                                            value:
                                                                                              item
                                                                                                .giftCardMessage[
                                                                                                index
                                                                                              ],
                                                                                            expression:
                                                                                              "item.giftCardMessage[index]",
                                                                                          },
                                                                                        ],
                                                                                      staticClass:
                                                                                        "gift-wrapping-engraving-input text-body-1 pa-4",
                                                                                      attrs:
                                                                                        {
                                                                                          maxlength:
                                                                                            "50",
                                                                                          placeholder:
                                                                                            _vm.$t(
                                                                                              "label.giftCardMessagePlaceholder"
                                                                                            ),
                                                                                        },
                                                                                      domProps:
                                                                                        {
                                                                                          value:
                                                                                            item
                                                                                              .giftCardMessage[
                                                                                              index
                                                                                            ],
                                                                                        },
                                                                                      on: {
                                                                                        input:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            if (
                                                                                              $event
                                                                                                .target
                                                                                                .composing
                                                                                            ) {
                                                                                              return
                                                                                            }
                                                                                            _vm.$set(
                                                                                              item.giftCardMessage,
                                                                                              index,
                                                                                              $event
                                                                                                .target
                                                                                                .value
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-divider",
                                                                                {
                                                                                  staticClass:
                                                                                    "mt-4",
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1205073041
                                                ),
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "px-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-body-2 font-weight-bold my-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "label.giftCardInstruction"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("textarea", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.giftInstruction,
                                                        expression:
                                                          "giftInstruction",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "gift-wrapping-engraving-input text-body-1 pa-4",
                                                    attrs: {
                                                      placeholder: _vm.$t(
                                                        "label.giftCardInstructionPlaceholder"
                                                      ),
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.giftInstruction,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.giftInstruction =
                                                          $event.target.value
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-expansion-panels",
                                    {
                                      staticClass:
                                        "my-2 mt-4 rounded-lg elevation-1",
                                      attrs: {
                                        flat: "",
                                        color: "transparent",
                                        tile: "",
                                        "border-color": "#D5DDE0",
                                      },
                                      model: {
                                        value: _vm.engravingModel,
                                        callback: function ($$v) {
                                          _vm.engravingModel = $$v
                                        },
                                        expression: "engravingModel",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel",
                                        {
                                          staticClass: "elevation-2 rounded-lg",
                                        },
                                        [
                                          _c(
                                            "v-expansion-panel-header",
                                            {
                                              staticClass: "pa-2 rounded-lg",
                                              attrs: { color: "#FFFFFF" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-body-2 font-weight-bold mt-2 px-4",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "label.engraving"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-body-2 font-weight-normal mt-2 mb-4",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "unbox_red--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "label.giftWrappingLimitedOffer"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "label.engravingDesc"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-expansion-panel-content",
                                            {
                                              staticClass: "rounded-lg",
                                              attrs: { color: "#FFFFFF" },
                                            },
                                            [
                                              _c("v-data-table", {
                                                ref: "cartTable",
                                                staticClass:
                                                  "cart-table unbox_scrollbar px-4",
                                                attrs: {
                                                  "items-per-page":
                                                    _vm.engravingDataset.length,
                                                  "item-key":
                                                    "productDisplaySku",
                                                  items: _vm.engravingDataset,
                                                  "hide-default-footer": "",
                                                  "no-data-text":
                                                    _vm.$t("label.cartEmpty"),
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "item",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          [
                                                            _c("tr", [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "full-width px-0 d-table",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card",
                                                                    {
                                                                      staticClass:
                                                                        "my-2 mx-1 rounded-lg",
                                                                      attrs: {
                                                                        color:
                                                                          "transparent",
                                                                        tile: "",
                                                                        elevation:
                                                                          "2",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "table",
                                                                        {
                                                                          staticClass:
                                                                            "full-width d-table",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "tr",
                                                                            [
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  staticClass:
                                                                                    "cart-productColumn image v-align-top",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "cart-productImage ma-2",
                                                                                      attrs:
                                                                                        {
                                                                                          src:
                                                                                            _vm
                                                                                              .shared
                                                                                              .MEDIA_SERVER_URL +
                                                                                            "/" +
                                                                                            item.imagePath,
                                                                                          alt:
                                                                                            item.name +
                                                                                            " " +
                                                                                            item.variation,
                                                                                          title:
                                                                                            item.name +
                                                                                            " " +
                                                                                            item.variation,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  staticClass:
                                                                                    "cart-productColumn",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "py-4 px-2",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "font-weight-bold text-sm-body-1 text-body-2 mb-2",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  item.name
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "font-weight-bold unbox_red--text mb-2 text-uppercase",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "font-weight-bold text-sm-body-1 text-body-2",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "RM0.00 "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "font-weight-light text-decoration-line-through unbox-secondary",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$shared.formatCurrency(
                                                                                                        item.sellingPrice
                                                                                                      )
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "v-row",
                                                                                        {
                                                                                          staticClass:
                                                                                            "mt-2",
                                                                                          attrs:
                                                                                            {
                                                                                              align:
                                                                                                "center",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-col",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  sm: "auto",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-sm-body-1 text-body-2",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "label.quantity"
                                                                                                      )
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "v-col",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  sm: "auto",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      width:
                                                                                                        "120px",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-text-field",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "cart-quantity",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          readonly:
                                                                                                            _vm.isMakeOldOrderPayment ||
                                                                                                            _vm.isClearBalanceOrder,
                                                                                                          "hide-details":
                                                                                                            "",
                                                                                                          outlined:
                                                                                                            "",
                                                                                                          dense:
                                                                                                            "",
                                                                                                          type: "number",
                                                                                                        },
                                                                                                      on: {
                                                                                                        keyup:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.checkAvailableGiftWrappingQuantity(
                                                                                                              "engText",
                                                                                                              item
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                      scopedSlots:
                                                                                                        _vm._u(
                                                                                                          [
                                                                                                            {
                                                                                                              key: "prepend",
                                                                                                              fn: function () {
                                                                                                                return [
                                                                                                                  _c(
                                                                                                                    "v-btn",
                                                                                                                    {
                                                                                                                      staticClass:
                                                                                                                        "font-weight-bold my-n2 text-h6 px-0",
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          disabled:
                                                                                                                            _vm.isMakeOldOrderPayment ||
                                                                                                                            _vm.isClearBalanceOrder,
                                                                                                                          "max-width":
                                                                                                                            "15px",
                                                                                                                          "min-width":
                                                                                                                            "15px",
                                                                                                                          height:
                                                                                                                            "40px",
                                                                                                                          text: "",
                                                                                                                        },
                                                                                                                      on: {
                                                                                                                        click:
                                                                                                                          function (
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            $event.stopPropagation()
                                                                                                                            return _vm.decreaseGiftWrappingQuantity(
                                                                                                                              "engText",
                                                                                                                              item
                                                                                                                            )
                                                                                                                          },
                                                                                                                      },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        " - "
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                ]
                                                                                                              },
                                                                                                              proxy: true,
                                                                                                            },
                                                                                                            {
                                                                                                              key: "append-outer",
                                                                                                              fn: function () {
                                                                                                                return [
                                                                                                                  _c(
                                                                                                                    "v-btn",
                                                                                                                    {
                                                                                                                      staticClass:
                                                                                                                        "font-weight-bold my-n2 text-h6 px-0",
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          disabled:
                                                                                                                            _vm.isClearBalanceOrder ||
                                                                                                                            _vm.isMakeOldOrderPayment ||
                                                                                                                            (_vm.cartStock.find(
                                                                                                                              function (
                                                                                                                                x
                                                                                                                              ) {
                                                                                                                                return (
                                                                                                                                  x.display_sku ==
                                                                                                                                  item.productDisplaySku
                                                                                                                                )
                                                                                                                              }
                                                                                                                            ) !=
                                                                                                                              null &&
                                                                                                                              _vm.cartStock.find(
                                                                                                                                function (
                                                                                                                                  x
                                                                                                                                ) {
                                                                                                                                  return (
                                                                                                                                    x.display_sku ==
                                                                                                                                    item.productDisplaySku
                                                                                                                                  )
                                                                                                                                }
                                                                                                                              )
                                                                                                                                .quantity <
                                                                                                                                item.quantity),
                                                                                                                          "max-width":
                                                                                                                            "15px",
                                                                                                                          "min-width":
                                                                                                                            "15px",
                                                                                                                          height:
                                                                                                                            "40px",
                                                                                                                          text: "",
                                                                                                                        },
                                                                                                                      on: {
                                                                                                                        click:
                                                                                                                          function (
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            $event.stopPropagation()
                                                                                                                            return _vm.increaseGiftWrappingQuantity(
                                                                                                                              "engText",
                                                                                                                              item
                                                                                                                            )
                                                                                                                          },
                                                                                                                      },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        " + "
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                ]
                                                                                                              },
                                                                                                              proxy: true,
                                                                                                            },
                                                                                                          ],
                                                                                                          null,
                                                                                                          true
                                                                                                        ),
                                                                                                      model:
                                                                                                        {
                                                                                                          value:
                                                                                                            item.quantity,
                                                                                                          callback:
                                                                                                            function (
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                item,
                                                                                                                "quantity",
                                                                                                                _vm._n(
                                                                                                                  $$v
                                                                                                                )
                                                                                                              )
                                                                                                            },
                                                                                                          expression:
                                                                                                            "item.quantity",
                                                                                                        },
                                                                                                    }
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]),
                                                            _vm.engravingConfirmQuantity >
                                                            0
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-form",
                                                                      [
                                                                        _c(
                                                                          "v-expansion-panels",
                                                                          {
                                                                            staticClass:
                                                                              "my-2 mt-4",
                                                                            attrs:
                                                                              {
                                                                                flat: "",
                                                                                color:
                                                                                  "transparent",
                                                                                "border-color":
                                                                                  "transparent",
                                                                              },
                                                                          },
                                                                          _vm._l(
                                                                            item.quantity,
                                                                            function (
                                                                              x,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "v-expansion-panel",
                                                                                {
                                                                                  key:
                                                                                    "giftWrapping-" +
                                                                                    index,
                                                                                  attrs:
                                                                                    {
                                                                                      multiple:
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-expansion-panel-header",
                                                                                    {
                                                                                      staticClass:
                                                                                        "pa-2 rounded-lg",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "#FFFFFF",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-sm-body-1 text-body-2 font-weight-bold mt-2",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item.fieldsName
                                                                                            ) +
                                                                                              " - " +
                                                                                              _vm._s(
                                                                                                index +
                                                                                                  1
                                                                                              ) +
                                                                                              " (" +
                                                                                              _vm._s(
                                                                                                item.name
                                                                                              ) +
                                                                                              ")"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-expansion-panel-content",
                                                                                    {
                                                                                      staticClass:
                                                                                        "rounded-lg",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "#FFFFFF",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "app-form-field",
                                                                                        {
                                                                                          staticClass:
                                                                                            "gift-wrapping-engraving-input text-body-1 pa-4",
                                                                                          attrs:
                                                                                            {
                                                                                              type: "textarea",
                                                                                              rows: 2,
                                                                                              placeholder:
                                                                                                _vm.$t(
                                                                                                  "label.engravingTextPlaceholder"
                                                                                                ),
                                                                                              rules:
                                                                                                _vm.$validation.serviceRules(
                                                                                                  item.fieldsIsRequired,
                                                                                                  item.fieldsRegex
                                                                                                ),
                                                                                            },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                item
                                                                                                  .giftCardMessage[
                                                                                                  index
                                                                                                ],
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    item.giftCardMessage,
                                                                                                    index,
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "item.giftCardMessage[index]",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "v-divider",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mt-4",
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            }
                                                                          ),
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2342726044
                                                ),
                                              }),
                                              _vm.engravingError
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "red--text text-body-2 ml-4 mb-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "label.incompleteEngraving"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { staticClass: "px-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-body-2 font-weight-bold my-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "label.giftCardInstruction"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("textarea", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.engravingInstruction,
                                                        expression:
                                                          "engravingInstruction",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "gift-wrapping-engraving-input text-body-1 pa-4",
                                                    attrs: {
                                                      placeholder: _vm.$t(
                                                        "label.engravingInstructionPlaceholder"
                                                      ),
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.engravingInstruction,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.engravingInstruction =
                                                          $event.target.value
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "mb-6" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight-bold mb-2 text-sm-body-1 text-body-2",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("label.selectPaymentMethod"))
                                  ),
                                ]
                              ),
                              _c(
                                "v-row",
                                _vm._l(_vm.paymentGateways, function (gateway) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: gateway.value,
                                      staticClass: "delivery-option-button-box",
                                      attrs: { cols: "12", sm: "5", md: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "cursor-pointer delivery-option-button text-capitalize px-4 py-2 rounded-lg",
                                          class:
                                            _vm.paymentGatewayId ==
                                            gateway.value
                                              ? "active"
                                              : "",
                                          on: {
                                            click: function ($event) {
                                              _vm.paymentGatewayId =
                                                gateway.value
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "checkout-payment-icon",
                                            attrs: {
                                              src:
                                                _vm.shared.MEDIA_SERVER_URL +
                                                "/images/payment_gateways/icons/" +
                                                gateway.name.toLowerCase() +
                                                ".png",
                                              alt: gateway.name,
                                              title: gateway.text,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "text-body-2",
                                              class:
                                                _vm.paymentGatewayId ==
                                                gateway.value
                                                  ? "unbox_black--text"
                                                  : "text_light_gray--text",
                                            },
                                            [_vm._v(_vm._s(gateway.text))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                              _vm.isPaymentMethodError
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "red--text text-body-2 mb-2",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("label.invalidPaymentMethod")
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.deliveryMethodId == _vm.deliveryMethod.SELF_PICKUP
                            ? _c(
                                "div",
                                { staticClass: "mb-6" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-weight-bold mb-2 text-sm-body-1 text-body-2",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("label.pickUpLocation"))
                                      ),
                                    ]
                                  ),
                                  _c("v-select", {
                                    staticClass: "mb-2",
                                    attrs: {
                                      "hide-details": "",
                                      dense: "",
                                      placeholder: _vm.$t("label.pleaseSelect"),
                                      items: _vm.outlets,
                                      disabled: _vm.isMakeOldOrderPayment,
                                    },
                                    on: { change: _vm.updateOutletInfo },
                                    model: {
                                      value: _vm.outletId,
                                      callback: function ($$v) {
                                        _vm.outletId = $$v
                                      },
                                      expression: "outletId",
                                    },
                                  }),
                                  _vm.outletInfo != null
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pickup-location-box text-sm-body-1 text-body-2",
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.outletInfo.addressOne)
                                            ),
                                          ]),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.outletInfo.addressTwo
                                              ) +
                                                " " +
                                                _vm._s(
                                                  _vm.outletInfo.addressThree
                                                )
                                            ),
                                          ]),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.outletInfo.postcode) +
                                                " " +
                                                _vm._s(_vm.outletInfo.city) +
                                                " " +
                                                _vm._s(_vm.outletInfo.state)
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.isSelectOutletError
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "red--text text-body-2 mb-2",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("label.invalidOutlet")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { md: "5", cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-6 rounded-lg",
                          attrs: {
                            color: "transparent",
                            tile: "",
                            elevation: "5",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                justify: "space-between",
                                "no-gutters": "",
                              },
                            },
                            [
                              _c("v-col", {
                                staticClass: "ma-0 pa-0",
                                attrs: { cols: "12" },
                              }),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "unbox_black--text text-body-1",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("label.shippingSubtotal"))
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "unbox_black--text text-body-1",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$shared.formatCurrency(0)) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-col", {
                                staticClass: "ma-0 pa-0 mb-2",
                                attrs: { cols: "12" },
                              }),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "unbox_black--text text-body-1",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("label.tax")))]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "unbox_black--text text-body-1",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$shared.formatCurrency(0)) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-col", {
                                staticClass: "ma-0 pa-0 mb-2",
                                attrs: { cols: "12" },
                              }),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "unbox_black--text text-body-1",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("label.subtotal")))]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "unbox_black--text text-body-1",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$shared.formatCurrency(
                                            _vm.cartSubtotalAmount
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-col", {
                                staticClass: "ma-0 pa-0 mb-2",
                                attrs: { cols: "12" },
                              }),
                              _vm.isDeposit ||
                              _vm.isClearBalanceOrder ||
                              _vm.depositAmount != 0
                                ? _c("v-col", { attrs: { cols: "auto" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "unbox_black--text text-body-1",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("label.depositAmount"))
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.isDeposit ||
                              _vm.isClearBalanceOrder ||
                              _vm.depositAmount != 0
                                ? _c("v-col", { attrs: { cols: "auto" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "unbox_black--text text-body-1",
                                      },
                                      [
                                        _vm._v(
                                          "-" +
                                            _vm._s(
                                              _vm.$shared.formatCurrency(
                                                _vm.isDeposit
                                                  ? _vm.depositAmount *
                                                      _vm.checkoutCart[0]
                                                        .quantity
                                                  : _vm.depositAmount
                                              )
                                            )
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c("v-col", {
                                staticClass: "ma-0 pa-0 mb-2",
                                attrs: { cols: "12" },
                              }),
                              !_vm.isDeposit
                                ? _c("v-col", { attrs: { cols: "auto" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "unbox_black--text text-body-1",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("label.promoCode"))
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              !_vm.isDeposit
                                ? _c("v-col", { attrs: { cols: "7" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "unbox_black--text text-body-1 text-right",
                                      },
                                      [
                                        !_vm.isEnterPromoCodeMode &&
                                        !_vm.isValidPromoCode
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "cursor-pointer text-decoration-underline px-0 link-button text-capitalize blue--text",
                                                attrs: {
                                                  depressed: "",
                                                  text: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.isEnterPromoCodeMode = true
                                                  },
                                                },
                                              },
                                              [_vm._v(" Enter Promo Code ")]
                                            )
                                          : !_vm.isValidPromoCode
                                          ? _c(
                                              "div",
                                              { staticClass: "text-right" },
                                              [
                                                _c("v-text-field", {
                                                  ref: "promoCodeField",
                                                  staticClass: "mb-2",
                                                  attrs: {
                                                    outlined: "",
                                                    dense: "",
                                                    "hide-details": "",
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.applyPromoCode.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.promoCode,
                                                    callback: function ($$v) {
                                                      _vm.promoCode = $$v
                                                    },
                                                    expression: "promoCode",
                                                  },
                                                }),
                                                _vm.invalidPromoCodeError
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "red--text text-caption mb-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.invalidPromoCodeError
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "cursor-pointer text-decoration-underline px-0 link-button text-capitalize blue--text",
                                                    attrs: {
                                                      type: "button",
                                                      depressed: "",
                                                      text: "",
                                                    },
                                                    on: {
                                                      click: _vm.applyPromoCode,
                                                    },
                                                  },
                                                  [_vm._v("Apply")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold text-body-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.promoCode.toUpperCase()
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "cursor-pointer text-decoration-underline px-0 link-button text-capitalize blue--text",
                                                    attrs: {
                                                      type: "button",
                                                      depressed: "",
                                                      text: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.changePromoCode,
                                                    },
                                                  },
                                                  [_vm._v("Change")]
                                                ),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _c("v-col", {
                                staticClass: "ma-0 pa-0 mb-2",
                                attrs: { cols: "12" },
                              }),
                              !_vm.isDeposit
                                ? _c("v-col", { attrs: { cols: "auto" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "unbox_black--text text-body-1",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("label.promoDiscount"))
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              !_vm.isDeposit
                                ? _c("v-col", { attrs: { cols: "auto" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "unbox_black--text text-body-1",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.promoDiscount > 0
                                                ? "-" +
                                                    _vm.$shared.formatCurrency(
                                                      _vm.promoDiscount
                                                    )
                                                : _vm.$shared.formatCurrency(
                                                    _vm.promoDiscount
                                                  )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c("v-col", {
                                staticClass: "ma-0 pa-0 mb-2",
                                attrs: { cols: "12" },
                              }),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "unbox_black--text text-body-1",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("label.surcharge")))]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "unbox_black--text text-body-1",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.order != null
                                          ? _vm.$shared.formatCurrency(
                                              _vm.order.surchargePrice
                                            )
                                          : _vm.$shared.formatCurrency(0)
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-col", {
                                staticClass: "ma-0 pa-0 mb-2",
                                attrs: { cols: "12" },
                              }),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bold unbox_black--text text-body-1",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("label.inTotal")))]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bold unbox_red--text text-body-1",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$shared.formatCurrency(
                                            _vm.cartTotalAmount
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm.noFullEnterCheckoutForm
                            ? _c(
                                "div",
                                { staticClass: "red--text text-body-2 my-4" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("label.pleaseSelectCheckout"))
                                  ),
                                ]
                              )
                            : _vm._e(),
                          !_vm.isMakeOldOrderPayment
                            ? _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "rounded-lg font-weight-bold text-body-1 py-6 white--text",
                                  attrs: { block: "", color: "unbox_red" },
                                  on: { click: _vm.placeOrder },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.isDeposit
                                          ? _vm.$t("label.payDeposit") +
                                              " [" +
                                              _vm.$shared.formatCurrency(
                                                _vm.depositAmount *
                                                  _vm.checkoutCart[0].quantity
                                              ) +
                                              "]"
                                          : _vm.$t("label.placeOrder")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.isMakeOldOrderPayment
                            ? _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "rounded-lg font-weight-bold text-body-1 py-6 white--text",
                                  attrs: { block: "", color: "unbox_red" },
                                  on: {
                                    click: _vm.confirmQuantityBeforePayment,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("label.makePayment")) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("app-dialog", {
        attrs: {
          dialogShow: _vm.changeAddressDialogShow,
          title: _vm.$t("action.changeAddress"),
          closeAction: function () {
            return (_vm.changeAddressDialogShow = !_vm.changeAddressDialogShow)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "changeAddressFrom-tab" },
                  [
                    _c("app-change-address", {
                      attrs: {
                        selectedAddressId: _vm.selectedAddressId,
                        updateSelectedAddress: _vm.updateSelectedAddress,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }